import React from "react"

const ProductListRow = ({ id, url, name, retailer }) => {
  return (
    <div className="product-row">
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <span className="link-spanner"></span>
      </a>
      <div className="product-row-name">{name}</div>
      <div className="product-row-cta">{`Go to ${retailer ? retailer : 'Product'} →`}</div>
    </div>
  );
}

export default function ProductsList({ products }) {
  return (
    <div className="product-table">
      {products.map(product =>
        <ProductListRow 
          key={product.mongodb_id}
          id={product.mongodb_id}
          url={product.affiliateUrl || product.scrapeUrl}
          retailer={product.retailer}
          name={product.name} />
        )}
    </div>
  );
}
