import React from "react";
import { graphql } from "gatsby";
import get from 'lodash.get';
import LaunchDetails from "../components/launchDetails";
import Layout from "../components/layout";
import ProductsList from "../components/productsList";

const retailers = [
  {
    key: 'amazon',
    name: 'Amazon'
  }, {
    key: 'newegg',
    name: 'Newegg'
  }, {
    key: 'adorama',
    name: 'Adorama'
  }, {
    key: 'bestbuy',
    name: 'Best Buy'
  }, {
    key: 'bhphoto',
    name: 'B&H Photo'
  }, {
    key: 'asusstore',
    name: 'Asus Store'
  }, {
    key: 'pnystore',
    name: 'PNY Store'
  }, {
    key: 'zotac',
    name: 'Zotac'
  }, {
    key: 'amdstore',
    name: 'AMD Store'
  }, {
    key: 'gamestop',
    name: 'GameStop'
  }, {
    key: 'target',
    name: 'Target'
  }, {
    key: 'walmart',
    name: 'Walmart'
  }, {
    key: 'microsoftstore',
    name: 'Microsoft Store'
  }, {
    key: 'meijer',
    name: 'Meijer'
  }, {
    key: 'pcrichardandson',
    name: 'PC Richard & Son'
  }, {
    key: 'playstationstore',
    name: 'PlayStation Store'
  }
];

const ListRetailer = ({ retailer, props }) => {
  const products = get(props, `data.${retailer.key}.nodes`);
  if (!products || products.length < 1) return null;
  const totalCount = get(props, `data.${retailer.key}.totalCount`);

  return (
    <div>
      <div className="products-header">
        <h2>{retailer.name}</h2>
        {(totalCount > 0) && 
          <div className="retailer-product-count">Total: {totalCount}</div>
        }
      </div>
      <ProductsList products={products} key={retailer.key} />
    </div>
  )
}

const list = ( props ) => {
  const relatedPages = get(props, 'pageContext.relatedPages', []);
  const name = get(props, 'pageContext.name', '');
  const launch = get(props, 'pageContext.launch');
  const totalCount = get(props, `data.all.totalCount`);

  return (
    <Layout relatedPages={relatedPages} name={name} showBurger={true} >
      <div className="products full-page-module">
        <div className="products-header">
          <h1>{name}</h1>
          {(totalCount > 0) &&
            <div className="retailer-product-count">Total: {totalCount}</div>
          }
        </div>
        {launch && <LaunchDetails launch={launch} />}
        {retailers.map(retailer =>
          <ListRetailer
            retailer={retailer}
            props={props}
            key={retailer.key}
          />
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: [String]) {
    all: allMongodbDevelopProducts(filter: {tags: {in: $tag}}, sort: {fields: name}) {
      totalCount
    },
    amazon: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Amazon"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    newegg: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Newegg"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    adorama: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Adorama"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    bestbuy: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Best Buy"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    bhphoto: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "B&H Photo"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    asusstore: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Asus Store"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    pnystore: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "PNY Store"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    zotac: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Zotac"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    amdstore: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "AMD Store"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    gamestop: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "GameStop"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    walmart: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Walmart"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    target: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Target"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    microsoftstore: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Microsoft Store"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    meijer: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "Meijer"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    pcrichardandson: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "PC Richard & Son"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    },
    playstationstore: allMongodbDevelopProducts(filter: {tags: {in: $tag}, retailer: {eq: "PlayStation Store"}}, sort: {fields: name}) {
      totalCount
      nodes {
        mongodb_id
        name
        scrapeUrl
        affiliateUrl
        retailer
        tags
      }
    }
  }
`

export default list;
