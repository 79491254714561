import React from "react"
import discordSS from "../images/discord-ss.png";

const joinUrl = 'https://discord.gg/xb4BFqb';

export default function LaunchDetails({ launch }) {
  return (
    <div className="launch-details">
      <div className="launch-details-content">
        <h2>{launch.title}</h2>
        <div className="launch-details-dates">
        {launch.dates.map(launchDate => 
          <div key={launchDate.name} className="launch-details-date">
            {launchDate.name}: {launchDate.date}
          </div>
        )}
        </div>
        <div className="launch-details-content-body">
          <div className="launch-details-content-body-cta">
            Get ready by heading to discord - emoji opt in and you'll be alerted shortly after listing pages go live.
            <a target="_blank" rel="noopener noreferrer" href={joinUrl}>
              <div className="launch-details-content-body-cta-button">OPT IN</div>
            </a>
          </div>
          <div className="launch-details-content-screenshot">
            <img src={discordSS} alt='discord-screenshot' />
          </div>
        </div>
        <div>Check back here for updates pre-launch!</div>
      </div>
    </div>
  );
}